<template>
  <Breadcrumb
    v-if="parentTitle"
    :parentTitle="parentTitle.toUpperCase()"
    :detailTitle="detailTitle.toUpperCase()"
  />
  <Breadcrumb
    v-else
    :detailTitle="detailTitle.toUpperCase()"
    parentTitle="&nbsp;"
  />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <form class="row g-3 form-menu-item-edit" novalidate>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "Menus.MenuItems.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="menuItemData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "CustomButtons.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="FormulaName"
                :disabled="true"
                v-model="menuItemData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6" v-if="!menuItemData.isCustomObject">
              <label for="Url" class="form-label"
                >{{
                  $t(
                    "Menus.MenuItems.Url",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Url"
                v-model="menuItemData.url"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col-md-6" v-else>
              <label for="CustomObject" class="form-label"
                >{{
                  $t(
                    "Menus.MenuItems.CustomObject",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :routeLink="String.format('#/CustomObject/Detail/')"
                @onChange="onChangeCustomObject"
                :selectedData="selectedCustomObject"
                :allowEmpty="false"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-CustomObjectsWithKey"
              />
            </div>
            <div class="col-md-6">
              <label
                for="Icon"
                class="form-label"
                :class="{ required: menuItemData.isSideBarItem }"
                >{{
                  $t(
                    "Menus.MenuItems.Icon",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <icon-picker
                v-model="menuItemData.icon"
                :allIconsText="
                  $t(
                    'Components.IconPicker.AllIcons',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <label for="IconColor" class="form-label"
                >{{
                  $t(
                    "Menus.MenuItems.IconColor",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }} </label
              ><br />
              <ColorPicker
                :value="menuItemData.iconColor"
                @valueChanged="colorPickerChange"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsCustomObject" class="form-label">{{
                  $t(
                    "Menus.MenuItems.IsCustomObject",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCustomObject"
                  @change="onChangeIsCustomObject"
                  v-model="menuItemData.isCustomObject"
                />
              </div>
              <div class="form-check">
                <label for="IsOpenNewTab" class="form-label">{{
                  $t(
                    "Menus.MenuItems.IsOpenNewTab",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsOpenNewTab"
                  v-model="menuItemData.isOpenNewTab"
                />
              </div>
              <div class="form-check">
                <label for="IsSideBarItem" class="form-label">{{
                  $t(
                    "Menus.MenuItems.IsSidebarItem",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsSideBarItem"
                  v-model="menuItemData.isSideBarItem"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="menuItemData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmitMenu('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconPicker from "@/components/custom/icon-picker/IconPicker";
import $ from "jquery";
export default {
  name: "MenuEditItem",
  components: {
    IconPicker,
  },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      menuItemData: {},
      parentTitle: "",
      detailTitle: "",
      selectedCustomObject: [],
    };
  },
  methods: {
    onChangeIsCustomObject() {
      this.menuItemData.url = null;
      this.menuItemData.key = null;
    },
    onChangeCustomObject(selected) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-CustomObjectGet?id={0}", selected.key))
        .then((response) => {
          this.menuItemData.key = response.data.key;
        });
    },
    colorPickerChange(value) {
      this.menuItemData.iconColor = value;
    },
    getCustomObjectByKey(key) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-CustomObjectGetByKey?key={0}", key))
        .then((response) => {
          this.selectedCustomObject.push({
            key: response.data.publicId,
            value: response.data.name,
          });
        });
    },
    getMenuAndParentDetail() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-MenuGet?id={0}", this.$route.params.menuId))
        .then((response) => {
          this.detailTitle = response.data.name;
        })
        .catch(function (error) {
          //alert(error);
        });

      if (!String.isNullOrWhiteSpace(this.$route.params.menuItemId)) {
        this.$prodGatewayAxios
          .get(
            String.format(
              "/Lcdp-MenuGetItem?id={0}",
              this.$route.params.menuItemId
            )
          )
          .then((response) => {
            this.parentTitle = response.data.name;
            this.defaultColor = response.data.iconColor;
            this.menuItemData = response.data;
            if (this.menuItemData.isCustomObject) {
              this.getCustomObjectByKey(this.menuItemData.key);
            }
          })
          .catch(function (error) {
            //alert(error);
          });
      }
    },
    onSubmitMenu(buttonName) {
      var form = $(".form-menu-item-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      if (
        this.menuItemData.isSideBarItem &&
        String.isNullOrWhiteSpace(this.menuItemData.icon)
      ) {
        this.errors.push(
          this.$t(
            "Menus.MenuItems.IsSidebarItemIconRequiredMessage",
            {},
            { locale: this.$store.state.activeLang }
          )
        );
        setTimeout(() => {
          this.errors = [];
        }, 4000);
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-MenuEditItem", { ...this.menuItemData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              String.format("/Menu/ListItem/{0}", this.$route.params.menuId)
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getMenuAndParentDetail();
  },
};
</script>
